export const ROUTES_PATH = {
  DEFAULTDASHBOARD: "/",
  DASHBOARD: "/dashboard",
  ORDERS: "/orders",
  TEAM: "/team",
  ACCOUNTS: "/accounts",
  PAGE404: "/Page404",
  STOCK: "/stock",
  PRODUCTS: "/products",
};

// These paths will be used in next PR.
