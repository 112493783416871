import { gql } from "@apollo/client";

export const UPDATE_TOKEN = gql`
  mutation UpdateToken($skip: Boolean!) {
    updateToken(skip: $skip) {
      message
      id
    }
  }
`;
