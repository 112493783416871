import { Stack, Typography, useTheme } from "@mui/material";
import { constantData } from "../../Assets/constants";
import TextRow from "./TextRow";

const PriceBookSkeleton = () => {
  const customTheme = useTheme();
  return (
    <Stack
      justifyContent={customTheme.alignment.alignItems.center}
      paddingBottom={customTheme.dimensions.dimen16}
      paddingTop={customTheme.dimensions.dimen0}
    >
      <Typography
        variant={constantData.TYPOGRAPHY_VARIANT.LABLE_LARGE_PROMINENT}
        mt={customTheme.dimensions.dimen16}
        mb={customTheme.dimensions.dimen8}
        textAlign={customTheme.alignment.alignItems.center}
        color={customTheme.palette.greyText.main}
      >
        {constantData.PRODUCT_CARD_TEXTS.NET_PRICE}
      </Typography>

      <Stack
        spacing={customTheme.dimensions.dimen4}
        justifyContent={customTheme.alignment.justifyContent.spaceBetween}
        padding={`${customTheme.dimensions.dimen0} ${customTheme.dimensions.dimen16}`}
      >
        <TextRow height={customTheme.dimensions.dimen20} />
        <TextRow height={customTheme.dimensions.dimen20} />
      </Stack>
      <Stack
        direction={customTheme.alignment.direction.row}
        spacing={customTheme.dimensions.dimen12}
        justifyContent={customTheme.alignment.justifyContent.spaceBetween}
        padding={`${customTheme.dimensions.dimen0} ${customTheme.dimensions.dimen16}`}
      >
        <Stack
          spacing={customTheme.dimensions.dimen4}
          justifyContent={customTheme.alignment.justifyContent.spaceBetween}
          width={customTheme.dimensions.percent.dimen50}
        >
          <TextRow height={customTheme.dimensions.dimen20} />
          <TextRow height={customTheme.dimensions.dimen20} />
        </Stack>
        <Stack
          spacing={customTheme.dimensions.dimen4}
          justifyContent={customTheme.alignment.justifyContent.spaceBetween}
          width={customTheme.dimensions.percent.dimen50}
        >
          <TextRow height={customTheme.dimensions.dimen20} />
          <TextRow height={customTheme.dimensions.dimen20} />
        </Stack>
      </Stack>
    </Stack>
  );
};
export default PriceBookSkeleton;
