export const display = {
  displayFlex: "flex",
  inlineFlex: "inline-flex",
  displayBlock: "block",
  displayNone: "none",
  flex3: 3,
  wrap: "wrap",
  displayWebKitBox: "-webkit-box",
  webKitLineClamp: {
    webKit2: 2,
  },
  webkitBoxOrient: {
    webKitVertical: "vertical",
  },
  overFlow: {
    overFlowHidden: "hidden",
    overFlowAuto: "auto",
    visible: "visible",
  },

  textOverFlow: {
    ellipsis: "ellipsis",
  },
  textDecoration: {
    lineThrough: "line-through",
    underline: "underline",
  },
  whiteSpace: {
    normal: "normal",
    nowrap: "nowrap",
  },

  pageSize: {
    page7: 7,
  },
  pageSizeOption: {
    pageSize7: 7,
  },
  buttonVariant: {
    contained: "contained",
    outlined: "outlined",
    standard: "standard",
  },
  cursor: {
    pointer: "pointer",
    default: "default",
  },
  pointerEvents: {
    pointerNone: "none",
    pointerAuto: "auto",
  },
  position: {
    sticky: "sticky",
    relative: "relative",
    start: "start",
    absolute: "absolute",
  },
};
