export const constantData = {
  PROJECT_TITLE: "FMCG",
  SEARCH_PLACE_HOLDER: "What you are looking for ?",
  DATA_NOT_FOUND: "DataNotFound",
  NO_OPTION: "No Option",
  NO_RECENT_SEARCH: "No Recent Search",
  MRP: "MRP",
  ROLES: "Roles",
  RIGHTS: "Rights",
  UPDATE: "Update",
  NONE: "none",
  ENTER: "Enter",
  TOTAL_DUE: "Total due",
  OVER_DUE: "Overdue",
  DASHBOARD: "Dashboard",
  ORDER: "Order",
  ORDERS: "Orders",
  PAYMENTS: "Payments",
  BUYERS: "Buyers",
  TEAM: "Team",
  SALES: "Sales",
  OPERATIONS: "Operations",
  ADMIN: "Admin",
  ALTERNATE: "alternate",
  CLIENT: "Client",
  CLIENTS: "Clients",
  CLIENT_DETAILS: "Client Details",
  VIEWALL: "View All",
  TEAM_MEMBER: "Team Members",
  ADD_TEAM_MEMBER: "ADD A TEAM MEMBER",
  CREATE_DELIVERY_ORDER: "CREATE DELIVERY ORDER",
  ADD_AN_ORDER: "ADD AN ORDER",
  ADD_PAYMENT: "Add Payment",
  BLOCKED: "BLOCKED",
  DATE_OF_JOINING: "Date of Joining",
  ERROR: "error",
  EXCESS: "Excess",
  PRICE: "Price",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  REQUIREMENT_ALERT_MESSAGE: "Please provide required data!",
  STRAIGHT_ARROW: "straightArrow",
  OUTSTANDINGS: "Outstanding",
  EARNINGS: "Earnings",
  MONTH_SALES: "This month sales",
  LACS: "Lacs",
  EXPECTED_STRING_MESSAGE: "Expected string but provided ",
  EMPTY_STRING: " is an empty string",
  ROLE_AND_RIGHTS: "Roles & Rights",
  BASIC_DETAILS: "Basic Details",
  MEMBER_FORM: "Member Form",
  DOCUMENTS: "Documents",
  SECURITY: "Security",
  EDIT: "Edit",
  NAME: "Name",
  USER_NAME: "USERNAME",
  EMAIL: "Email",
  PHONE: "Phone",
  JOINING_DATE: "Joining Date",
  STREET: "Street",
  PINCODE: "Pincode",
  CITY: "City",
  STATE: "State",
  COUNTRY: "Country",
  ADD_ONE: "Address Line 1",
  ADD_TWO: "Address Line 2",
  NOT_ASSIGNED: "Unassigned",
  DOCUMENTS_NAME: "Document Name",
  UPLOAD: "UPLOAD",
  ADD_DOCUMENT: "ADD DOCUMENT",
  UPLOAD_DOCUMENTS_ERROR: "Please upload document(pdf or any image).",
  UPLOAD_DOCUMENTS_NAME_ERROR: "Please enter name of Document.",
  UNKNOWN: "Unknown",
  ADD_ACCOUNT: "ADD ACCOUNT",
  ADD_NEW_DOCUMENT: "ADD NEW DOCUMENT",
  ACCOUNT: "Account",
  ACCOUNTS: "Accounts",
  STOCK: "Stock",
  PRODUCTS: "Products",
  ITEMS: "items",
  ITEM: "item",
  HANDLER: "Handler",
  PRIMARY: "Primary",
  GO_TO_PIPELINE: "GO TO PIPELINE",
  CURRENT_PIPELINE_VALUE: "Current Pipeline Value",
  UNSOLD: "unsold",
  PER_CTN: "per ctn.",
  PER_PCS: "perc pcs",
  PCS: "pcs",
  PIPELINE: "Pipeline",
  SEARCH_PRODUCT: "Search & Add Products",
  PER: "PER",
  CANCEL: "CANCEL",
  SAVE: "SAVE",
  AVAILABLE: "available",
  QTY: "QTY",
  PRICE_PER_PCS: "Price per pcs",
  BACK: "Back",
  UPDATE_ITEM: "UPDATE ITEM",
  RECENTLY_PURCHASED: " Recently Purchased",
  ADVANCE: "Advance",
  UPDATE_QUANTITY: "UPDATE QUANTITY",
  UPDATE_PRICE: "UPDATE PRICE",
  UPDATE_PAYMENT: "UPDATE PAYMENT",
  EXCEEDED_LIMIT: "Exceeded Limit",
  BANK: "BANK",
  CASH: "CASH",
  CARD: "CARD",
  PAYMENT_DATE: "Payment Date",
  MAKE_PAYMENT: "MAKE PAYMENT",
  ENTER_AMOUNT: "Enter Amount",
  CREDIT_USED: "Credit Used",
  CONFIRM: "CONFIRM",
  DONE: "DONE",
  SEND_ORDER_FOR_EXECUTION: "SEND ORDER FOR EXECUTION",
  PRICE_PER_CTN: "Price per ctn",
  ORDER_DETAILS: "Order Details",
  CREDIT_USAGE: "Credit Usage",
  PENDING: "Pending",
  CTN: "ctn",
  REMOVE: "Remove",
  QUANTITY: "Quantity",
  FULFILLED: "Fulfilled",
  CLOSE: "Close",
  EDIT_PIPELINE: "EDIT PIPELINE",
  PROCESS_ORDERS: "Process Order",
  DEFAULT: "default",
  REPORT: "Reports",
  HELP: "Help & Support",
  SETTINGS: "Settings",
  DARK_MODE: "Dark Mode",
  LOGOUT: "Logout",
  WAREHOUSES: "Warehouses",
  WAREHOUSE: "Warehouse",
  ADD_NEW_WAREHOUSE: "ADD NEW WAREHOUSE",
  CREATE_WAREHOUSE: "CREATE WAREHOUSE",
  FOR_AMOUNT: "For amount",
  DELIVERY: "Delivery",
  DISPATCH: "Dispatch",
  LOAD_MORE: "LOAD MORE",
  LEGAL_NAME: "Legal Name",
  TYPE: "Type",
  CREDIT_LIMIT: "Credit Limit",
  CHANGE_LIMIT: "CHANGE LIMIT",
  NO_CREDIT_LIMIT: "No Credit Limit Granted",
  AMOUNT: "AMOUNT",
  DAYS_: "DAYS",
  ADDRESS: "Address",
  NO_STOCK_FOUND: "No Stock Found",
  ADD_EDIT_ADDRESS: "Add/Edit Address",
  INVOICE_DETAILS: "Invoice Details",
  ADD_INVOICE_DETAILS: "Add Invoice Details",
  SUPPLIER: "Supplier",
  PURCHASE_INVOICE: "Purchase Invoice",
  ADD_NEW_INVOICE: "ADD NEW INVOICE",
  TOTAL_OUTSTANDING: "Total Outstanding",
  DAY: "Day",
  CHANGE_HANDLER: "Change Handler",
  CURRENT_HANDLER: "Current Handler",
  EDIT_INVOICE_DETAILS: "Edit Invoice Details",
  ADJUST_STOCK_OPENNG: "Adjust Stock Opening",
  PCS_IN_CARTON: "Pieces in Carton",
  CREATE_OPENING: "Create Opening",
  ADD_PRODUCT: "ADD PRODUCTS",
  NET_PRICE: "Net Price",
  NET_DISC: "net disc.",
  REPRESENTATIVE: "Representative",
  LAST_ORDER_UPDATED_BY: "Last Order Updated By",
  LAST_ORDER_UPDATED_ON: "Last Order Updated On",
  ADD: "Add",
  TOTAL_VALUE: "Total Value",
  INCOMING: "Incoming",
  BOOKING: "Booking",
  NET_DISCOUNT: "Net Discount",
  CREATE_ORDER: "Create Order",
  GST_NO: "Gst No",
  SELECT_STATE: "Select State",
  SOMETHING_WENT_WRONG: "Something went wrong",
  CREATE_CLIENT: "CREATE CLIENT",
  COMPANY: "Company",
  ADDITIONAL_DISCOUNT: "Additional Disc.",
  DISC: "Disc",
  ADD_COMPANY_RATE: "Add Company Rate",
  CONTINUE: "CONTINUE",
  YES: "Yes",
  NO: "No",
  PRICE_BOOK_NOT_SET_YET: "Price book not set yet",
  PREVIOUS: "PREVIOUS",
  NEXT: "NEXT",
  ADD_NEW_CONTACT: "ADD NEW CONTACT",
  MEMBERS: "Members",
  BLOCK: "Block",
  UNBLOCK: "Unblock",
  ADDRESSES: "Addresses",
  DISCOUNT: "Discount",
  PRODUCTS_NOT_FOUND: "Products not found",
  INCOMING_ORDERS: "Incoming Orders",
  TOTAL: "Total",
  COMPLETED_ORDERS: "Completed Orders",
  ORDER_TYPE: "Order Type",
  DATE_RANGE: "Date Range",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  PROCEED: "Proceed",
  ACCOUNTS_BOOK: "Accounts Book",

  //typography variant

  TYPOGRAPHY_VARIANT: {
    DISPLAY_LARGE: "displayLarge",
    DISPLAY_LARGE_BOLD: "displayLargeBold",
    DISPLAY_MEDIUM: "displayMedium",
    DISPLAY_SMALL: "displaySamll",
    SEMI_BOLD: "semiBold",
    SEMI_BOLD_MEDIUM: "semiBoldMedium",
    SEMI_BOLD_PROMINENT: "semiBoldProminent",
    HEADLINE_MEDIUM: "headlineMedium",
    HEADLINE_SMALL: "headlineSmall",
    HEADLINE_BOLD: "headlineBold",
    TITLE_LARGE: "titleLarge",
    TITLE_MEDIUM: "titleMedium",
    TITLE_BOLD: "titleBold",
    TITLE_SMALL: "titleSmall",
    LABLE_LARGE: "lableLarge",
    LABLE_LARGE_PROMINENT: "lableLargeProminent",
    LABLE_MEDIUM: "lableMedium",
    LABLE_MEDIUM_PROMINENT: "lableMediumProminent",
    LABLE_SMALL: "lableSmall",
    BODY_LARGE: "bodyLarge",
    BODY_MEDIUM: "bodyMedium",
    BODY_SMALL: "bodySmall",
  },
  SEVERITY: {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning",
  },
  ANIMATION: {
    BORDER_RADIUS_30S_EASE_IN_OUT: "border-radius 30s ease-in-out",
  },
  TEXT_FIELD_VARIANT: {
    OUTLINED: "outlined",
    STANDARD: "standard",
  },
  CHIP_VARIANT: {
    FILLED: "filled",
  },

  STYLING: {
    BG_COLOR: "background.paper",
  },
  DATATYPE: {
    STRING: "string",
    OBJECT: "object",
    NUMBER: "number",
    BOOLEAN: "boolean",
    FUNCTION: "function",
  },
  BUTTON_VARIANT: {
    OUTLINED: "outlined",
    CONTAINED: "contained",
    TEXT: "text",
  },

  AVTAR_VARIANT: {
    ROUNDED: "rounded",
    SQUARE: "square",
  },

  STATUS_CODE: {
    CODE404: 404,
    CODE200: 200,
    CODE201: 201,
  },

  SECURITY_UPDATES: {
    CHANGE_PASSWORD: "Change Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
    ENABLE_TWO_FACTOR_AUTHENTICATION: "Enable two factor Authentication",
    UPDATE_PASSWORD: "Update Password",
    LOGOUT_FROM_ALL_DEVICES: "LOGOUT USER FROM ALL DEVICES",
  },

  CREDIT_LIMIT_CARD_MSG: {
    EXCEEDED_CREDIT_LIMIT: "Exceeded Credit Limit by",
    MAX_LIMIT: "You have exceeded to maximum limit of ",
    CREDIT_MAX_LIMIT: "You have a maximum limit of ",
    PAST_CONSUMED_CREDITS: "Past Consumed Credits",
    ORDER_VALUE: "+ Order Value",
    TOTAL_CREDIT_CONSUMED: "= Total Credits Consumed",
    SKIP_FOR_NOW: "SKIP FOR NOW",
    COMPLETED_ORDER_VALUE: "Completed Order Value",
    IN_PROGRESS_ORDER_VALUE: "In-progress Order Value",
    CREDIT_USAGE: "Credit Usage",
    CHANGE_CREDIT_LIMIT: "Change Credit Limit",
    CREDIT_USED: "Credit Used",
  },

  SHIPPING_ADDRESS_MSG: {
    NEW_SHIPPING: "New Shipping Address",
    ADDRESS_MSG: "We have never delivered to this address of ",
    CONFIRM_ADDRESS_MSG: ". Are you sure want to continue with this address?",
    NEW_SHIPPING_ADDRESS: "New Shipping Address Approved",
    NO_APPROVAL_NEEDED: "No Approval needed",
    WE_ARE_GOOD_TO_DELIVER: "We are good to deliver",
    SAVED_ADDRESS: ". is saved as the shipping address",
  },

  PAGE_404_MESSAGES: {
    // CODE_404: "404",
    PRIOR_MSG: "Oops! This Page Could Not Be Found",
    DESCRIPTION:
      "SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAVE BEEN REMOVED.NAME CHANGED OR IS TEMPORARILY UNAVAILABLE.",
    BUTTON_TEXT: "GO TO DASHBOARD",
  },
  POP_OVER_LIST: {
    CANCEL_ORDER: "Cancel Order",
    PUT_ON_HOLD: "Put On Hold",
    DETAILS: "Details",
  },
  TEXT_FIELD_TYPE: {
    PASSWORD: "Password",
    TEXT: "Text",
    FILE: "file",
  },

  ARROW_TYPES: {
    UPWARD_STRAIGHT: "upwardStraight",
    UPWARD_TILT: "upwardTilt",
    DOWNWARD_STRAIGHT: "downwardStraight",
    DOWNWARD_TILT: "downwardTilt",
  },
  HEADINGS: {
    MOST_SOLD_PRODUCT: "Most Sold Products",
    RECENTLY_ADDED: "Recently Added",
    PRICE_DROPPED: "Price Dropped",
    LOWEST_PRICE_PRODUCT: "Lowest Price Product",
    TOP_EARNERS: "Top Earners",
    MY_CLIENTS: "My Clients",
    ORDER_STATUS: "Order Status",
    OUTSTANDING: "Outstanding",
  },

  PRODUCT_CARD_TEXTS: {
    NET_PRICE: "Net Price",
    PER_PCS: "Per pcs",
    PER_CTN: "Per ctn.",
    NET_DISC: "Net Disc.",
    COMPANY_RATE_RATE: "Company Rate",
    ADD_DISC: "Add. Discount",
    EMPTY_MSG: "Product is not available in this warehouse.",
  },

  ORDER_NAME: {
    DELIVERY_ORDER: "Delivery Orders",
    DISPATCH_ORDER: "Dispatch Orders",
    SALES_ORDER: "Sales Order",
    UNFULLFILLED_ITEMS: "Unfullfilled Items",
  },

  TEAM_CATEGORY: {
    SALES: "Sales",
    OPERATIONS: "Operations",
    ADMIN: "Admin",
  },

  DOCUMENTS_TYPE: {
    APPLICATION_PDF: "application/pdf",
    IMAGE_JPG: "image/jpg",
    IMAGE_JPEG: "image/jpeg",
    IMAGE_PNG: "image/png",
  },

  REVIEW_ORDER_SUMMARY: {
    REVIEW_ORDER: "Review Order #",
    ORDER_DATE: "Order Date",
    AMOUNT: "Amount",
    WAREHOUSE: "Warehouse",
    BUYER: "Buyer",
    BILLING_ADDRESS: "Billing Address",
    MODE: "Mode",
    CREDIT_LIMIT: "Credit Limit",
    SHIPPING_ADDRESS: "Shipping Address",
    MSG: "No orders present as of now",
    GOOD_TO_GO: "Good to go",
    LAST_MODIFIED: "Last Modified",
    CHOOSE_ANOTHER: "SELECT OTHER",
    APPROVALS_PENDING: "Approvals pending",
    REJECT: "REJECT",
    EMPTY_LIST_MSG: "There's no product added in list.",
  },

  ADD_ACCOUNT_MSG: {
    MSG: "No accounts present as of now,",
    CREATE_ACCOUNT: " please create account in order to proceed",
    SELECT_MSG: "Please select account to check the details",
    ADD_PIPLINE: "Your pipeline is empty, please add products in pipeline",
  },

  PIPELINE_MSG: {
    SELECT_MSG: "Please select item to update the price or quantity",
    SAVE_PIPELINE: "SAVE PIPELINE",
    DELIVER_MSG: "We are good to deliver",
    PRODUCT_DETAILS: "Product Details",
    START_ADDING_PRODUCTS: "Start adding  products to pipeline",
    CUSTOMER_REQUIREMENTS:
      "Pipelines helps in managing and tracking customers requirements",
    ELIGIBLE_FOR: "Eligible for",
    BULK_ORDER: "Bulk Order",
    ADD_DISCOUNT: "Add. Discount",
    ADD_DISC: "Add. Disc:",
    TO_UNLOCK: "to unlock",
    SELECT: "SELECT",
    ADDITIONAL_DISCOUNT_OF: "Addititonal Discount of",
    PIPELINE_PRODUCT_UPDATED: "Pipeline product updated.",
    SELECT_PIPELINE: "Select Client",
    SET_SELLING_PRICE: "Set Selling Price",
    SET_SELLING_PRICE_MSG: "Selling Price Not added yet",
    PIPELINE_READY: "Pipeline is ready for order creation of value",
  },
  PROCESS_ORDER: {
    WE_ARE_GOOD_TO_PROCESS_ORDER: "We are good to Process Order",
    WILL_WE_PROCESS_THE_ORDER_FROM: "will be process from",
    OF_AMOUNT: "of amount",
    CREATE_PROCESS_ORDER: "CREATE PROCESS ORDER",
    CREDIT_USED: "Consumed Credit",
    TOTAL_AMOUNT: "Total Amount",
    WAREHOUSE: "Warehouse",
    DEALER_DETAILS: "Dealer Details:",
    BILLING_ADDRESS: "Billing Address",
    SEND_ORDER_FOR_REVIEW: "SEND ORDER FOR REVIEW",
    UNFULLFILLED: "EXCLUDED",
    ADD_TO_ORDER: "ADD TO ORDER",
  },

  BILLING_ADDRESS: {
    ADD_BILLING: "Add Billing Address",
    ADD_NEW: "ADD NEW BILLING ADDRESS",
    CONFIRM_ADDRESS: "CONFIRM BILLING ADDRESS",
  },

  SHIPPING_ADDRESS: {
    ADD_SHIPPING: "Add Shipping Address",
    ADD_NEW: "ADD NEW SHIPPING ADDRESS",
    CONFIRM_ADDRESS: "CONFIRM SHIPPING ADDRESS",
  },

  TEAMS_MSG: {
    SELECT_MSG: "Please select Member to check the details.",
    EMPTY_LIST_PRIMARY_MSG: "No members present as of now,",
    EMPTY_LIST_SECONDARY_MSG: "please create Member.",
    ADD_ROLES: "ADD ROLES",
    PLEASE_CREATE_TEAM: "Please create team",
    NO_TEAM_FOUND: "No team found",
    EDIT_TEAM: "Edit Team",
    DELETE_TEAM: "Delete Team",
    ADD_TEAM: "ADD TEAM",
    ADD_MEMBER: "ADD MEMBER",
  },
  MEMBER_PROFILE_MSG: {
    EMPTY_ORDER_LIST_MSG: "Your order list is empty.",
    EMPTY_BUYER_LIST_MSG: "Your buyer's list is empty.",
    EMPTY_PAYMENT_LIST_MSG: "Your payments list is empty.",
  },

  VIEW_DETAIL_MSG: {
    UPDATE_DETAIL_MSG: "Member Details Updated Successfully",
    UPDATE_CLIENT_MSG: "Client Details Updated Successfully",
    UPDATE_PASSWORD_MSG: "Password Updated Successfully",
  },
  CREATE_MEMBER_POPUP: {
    CREATE_MEMBER: "CREATE MEMBER",
    CREATE_MEMBER_MSG: "Please provide basic details for new member",
    SELECT_ANYONE: "Select Anyone",
    NO_DEPARTMENT_FOUND: "No department found",
    MEMBER_CREATED_MSG: "Member Succesfully Created!!!",
  },
  ID: {
    UN_ASSIGNED: "9fd023dc-d615-477b-afe9-c78a2d256c8b",
  },

  ACCOUNT_MSG: {
    CREATE_ACCOUNT: "CREATE ACCOUNT",
    CREATE_ACCOUNT_MSG: "Please provide basic details for new account",
    LEGAL_NAME: "Legal Name",
    ACCOUNT_CREATED_MSG: "Account Created Successfully",
    NO_HANDLER: "No Handler Assigned.",
    ADD_NOW: "Add Now?",
    HANDLER_UPDATED: "Handler Updated Successfully",
  },

  WAREHOUSE_MSG: {
    CREATE_WAREHOUSE: "please create new warehouse",
    EMPTY_LIST: "No warehouse present as of now,",
    SUCCESS: "Warehouse created successfully",
    CREATE_WAREHOUSE_MSG: "Please provide basic details for new warehouse",
    PRIMARY_MEMBER: "Primary Member",
    NO_MEMBER_FOUND: "No Member Found",
    UPDATE: "Warehouse updated successfully",
    SELECT_WAREHOUSE: "Select Warehouse",
    PENDING: "Pending",
    DELIVERED: "Delivered",
    CREATE_NEW_INCOMING_ORDER: "CREATE NEW INCOMING ORDER",
    VIEW_EXISTING_INCOMING_ORDERS: "VIEW EXSITING INCOMING ORDER(S)",
    ASSETS: "Assets",
    HANDLER: "Handler",
    RESUME: "Resume",
    VIEW: "View",
    IN_EXECUTION: "In Execution",
    IN_DISPATCH: "In Dispatch",
  },
  COMPANY_REPRESENTATIVE_TEXT: {
    REPRESENTATIVE: "Representative",
    COMPANY_REPRESENTATIVE: "Company Representative",
    ADD_REPRESENTATIVE: "ADD REPRESENTATIVE",
    POSITION: "POSITION",
    SET_AS_PRIMARY: "Set as Primary",
    BLOCK: "BLOCK",
    UNBLOCK: "UNBLOCK",
    BLOCK_REPRESENTATIVE: "Block Representative",
    BLOCK_CONFIMATION_MSG: "Are you sure, you want to block",
    UNBLOCK_CONFIMATION_MSG: "Are you sure, you want to unblock",
    NO_REPRESENTATIE_FOUND: "No Representative Found",
  },
  TAX_DETAILS_TEXT: {
    TAX_DETAILS: "Tax Details",
    ADD_ADDRESS: "ADD ADDRESS",
    SET_AS_DEFAULT: "Set as Default",
    ADD_GST: "ADD GST NUMBER",
    ADDRESS_TYPE: "Address Type",
    GST_NUMBER: "GST NUMBER",
    GSTIN: "GSTIN",
    DEFAULT: "Default",
    NO_ADDRESS_FOUND: "No Address Found",
  },

  DEPARTMENT_MSG: {
    CREATE_DEPARTMENT: "Department created successfully",
    UPDATE_DEPARTMENT: "Department updated successfully",
    PRIVILEGES_UPDATED: "Privileges updated successfully",
    DELETE_TEAM: "Department deleted successfully",
    DEPARTMENT_AND_PRIVILEGES: "Department and privilegs updated successfully",
  },

  INVOICE_DETAILS_MSG: {
    INVOICE_NO: "Invoice No.",
    INVOICE_DATE: "Invoice Date",
    ADD_BATCH_NUMBER: "Add Batch Number",
    ADD_ANOTHER_BATCH: "Add another batch",
    BATCH_NO: "Batch No.",
    MANUFACTURING_DATE: "Mfg. Date.",
    EXPIRY_DATE: "Exp. Date",
    CONFIRM_DELIVERY: "Confirm Delivery",
    CONFIRM_ITEM: "Confirm Item",
    WAREHOUSE: "Warehouse",
    SUPPLIER: "Supplier",
    ADD_NEW_SUPPLIER: "Add New Supplier",
    SUPPLIER_ADDRESS: "Supplier's Address",
    ADD_NEW_ADDRESS: "Add New Address",
    NO_WAREHOUSE: "No Warehouse Found",
    NO_SUPPLIER: "No suppliers Found",
    CONFIRM_INVOICE: "CONFIRM INVOICE DETAILS",
    NO_ADDRESS: "No Address Found",
    SUCCESS_MSG: "Successfully Created",
    NO_INVOICE_FOUND: "No Invoice Found",
    DELIVERY_CONFIRMED: "Delivery Confirmed",
    ADD_PRODUCTS_MSG: "Search to Add products in your invoice",
    ACTION_CARD_MSG: "Please select items for confirming the order",
    PURCHASED_QUANTITY: "PURCHASE QUANTITY",
    ADD_BATCH: "Add Batch",
    BATCHES_ADDED: "BATCHES ADDED",
    DELETE_SUCCESS: "Successfully Deleted",
    PRICE_IN_INR: "Price (in inr)",
    SUCCESS_UPDATE: "Successfully Updated",
    DRAFT: "draft",
    COMPLETED: "completed",
    ORDER_ID: "Order ID",
    ORDER_DATE: "Order Date",
    LAST_MODIFIED: "Last Modified",
    CREATED_BY: "Created by",
    LAST_MODIFIED_BY: "Last Modified by",
    PURCHASE_ORDER_DETAILS: "Purchase Order Details",
  },

  PATTERN: {
    PERCENTAGE_OFF: (percentageOff) => `${percentageOff}% OFF`,
    LAST_DATA: (lastData) => `Last Payment: ${lastData} |`,
    DAYS: (day) => `${day} Days`,
    DAY: (day) => `${day} Day`,
    APPROVALS_REQUIRED: (count) => `${count} Approvals Required`,
    STOCK_QTY: (qty) => `${qty} Quantity`,
    UPDATE_PRICE: (
      currentPrice,
      actualPrice
    ) => `Price of the order (${currentPrice}) doesn’t match with expected price
    (${actualPrice}). Do you want to keep the price?`,
    CONFIRMATION_MSG: (currentPrice) =>
      `Order will be executed with Price ${currentPrice} per pc.`,
    CONFIRMATION_QUANTITY: (qty) =>
      `Order will be executed with ${qty} Quantity`,
    UPDATE_MSG: (price, per, name) =>
      `Price ${price} per ${per} has been approved by ${name}.`,
    PAYMENT: (payment) => `Payment of ${payment} required to process order`,
    CREDIT_USED: (amount) => `A credit of  ${amount} remaining.`,
    CREDIT_LIMIT: (amount, day) => `Credit Limit  ${amount} / ${day} days`,
    TOTAL_PRODUCT: (product) => `${product} items`,
    LIMIT_EXCEED: (limit) =>
      `Limit will exceed by ${limit} if you place this order`,
    ITEMS: (item) => `${item} ITEMS`,
    QTY: (qty) => `Qty: ${qty} ctn`,
    PRICE: (price) => `| Price: ${price} per piece`,
    DISCOUNTING_ON: (discounting) => `Discounting on ${discounting}`,
    NO_ORDER: (order) => `No ${order} orders found`
  },
  ALL_PRODUCTS: {
    FILTERS: "Filters",
    CLEAR_ALL: "Clear All",
    AVAILABiLITY: "Availability",
    DISCOUNT: "Discount",
    PRICE: "Price",
    PACK_OF: "Pack of ",
    APPLY: "Apply",
    NO_PRODUCT_FOUND: "No Products Found",
    ADD: "ADD",
    FEATURES: "Features",
    SEARCH_PIPELINE: "Search Pipeline or Create Pipeline",
  },

  ADJUST_OPENING: {
    OPENING_STOCK: "Opening Stock",
    SET_OPENING_BALANCE: "Set Opening Balance (in ctn.)",
    SUCCESS_MSG: "Created Successfully",
    SELECT_MSG: "Please Select Product to Update Opening Balance",
    NO_WAREHOUSE_FOUND: "No warehouse found",
    PLEASE_CREATE_WAREHOUSE: "Please add warehouse",
    ADD_WAREHOUSE: "ADD WAREHOUSE",
  },
  PRICE_BOOK: {
    DISCOUNT: "Discount %",
    NET_PRICE: "Net Price",
    SET_BULK_RATE: "Set Bulk Rate",
    NET_DISCOUNT_ON_PRODUCT: "Net Discount on Product is",
    UPDATE_SELLING_PRICE: "Update Selling Price",
    SELECT_MSG: "Please Select Product to Set the Selling Price",
    DISCOUNT_MSG: "Set Discount (in ctn.)",
    DISCOUNT_MSG_PCS: "Set Discount (in pcs.)",
    SET_COMPANY_DISCOUNT: "Set Comapany Rate (in ctn.)",
    COMPANY_RATE: "Company Rate",
    ADDITIONAL_DISCOUNT: "Additional Discount (in ctn.)",
    MIN_QUANTITY: "MIN. QTY.",
    ADD_DISCOUNT: "ADD DISC",
    ADD_SLAB: "Add Slab",
    CREATE_SELLING_PRICE_MSG: "Selling Price Created",
    UPDATE_SELLING_PRICE_MSG: "Selling Price Updated",
  },
  PRODUCT_DETAILS_TEXTS: {
    SPECIAL_PRICE: "Special price",
    OFFERS_AND_INFO: "Offers & Information",
    ADD_TO_CURRENT_PIPELINE: "ADD TO CURRENT PIPELINE",
    ADD_TO_ANOTHER_PIPLINE: "SELECT ANOTHER PIPLINE",
    UPDATE_PRODUCT: "UPDATE PRODUCT",
    IN_STOCK: "In Stock",
    OUT_OF_STOCK: "Out of Stock",
    UPDATE_TO_CURRENT_PIPELINE: "UPDATE TO CURRENT PIPELINE",
  },

  CREATE_ORDER_TEXTS: {
    EXCLUDED_ITEMS: "Excluded Items",
    YOUR_ORDER_FOR: "Your order for",
    YOUR_ORDER_IS: "Your order ID is:",
    OVERFILLED: "Overfilled",
    PARTIAL: "Partial",
    OVERFILLED_MESSAGE: "Ordered quantity exceeds in hand stock",
    PARTIAL_MESSAGE: "Requested Quantity is ",
  },

  STOCK_TEXTS: {
    PRODUCT_STATUS: "Product Status",
    SELECT_STATUS_MSG: "Please select status of the product.",
    SELLING_PRICE_CREATED: "Selling price created successfully. ",
  },
  EXECUTE_ORDER_MSG: {
    EXECUTE_ORDER: "Execute Order",
    PARENT_ORDER: "Parent Order",
    SHIPPING_ADDRESS: "Shipping Address",
    BILLING_ADDRESS: "Billing Address",
    EDIT_DISPATCH_ORDER_DETAILS: "EDIT DISPATCH ORDER DETAILS",
    ADD_DISPATCH_ORDER_DETAILS: "ADD DISPATCH ORDER DETAILS",
    CREATE_DISPATCH_ORDER: "CREATE DISPATCH ORDER",
    DISPATCH_ORDER_DETAILS: "Dispatch Order Details",
    DISPATCH_ORDER_MSG:
      "Dispatch has not been added yet. Please provide the necessary details to create the dispatch order.",
    TRANSPORTATION_TYPE: "Transportation Type",
    TRANSPORTATION: "Transportation",
    TERMS_OF_DELIVERY: "Terms of Delivery",
    DETAILS: "Details",
    VEHICLE_DETAILS: "Vehicle Details",
    ADD_VEHICLE_NUMBER: "Add Vehicle Number",
    ADD_VEHICLE: "ADD VEHICLE",
    ADDITIONAL_CHARGES: "Additional Charges",
    OTHER_CHARGES: "Other Charges",
    OTHER_CHARGES_NAME: "Other Charges Name",
    OTHER_CHARGES_VALUE: "Other Charges Value",
    MODE_OF_TRANSPORTATION: "Mode of Transportation",
    NO_OF_VEHICLE: "No. of Vehicles",
    VEHICLES_NO: "Vehicles No.",
    DETAIL_UPDATED_SUCCESSFULLY: "Details Updated Successfully.",
    DELETE_MSG: "Number of vehicles exceeds the entered number.",
  },
  PURCHASE_ORDERS_TEXTS: {
    INCOMING_ORDERS: "Incoming Orders",
    NO_DETAILS_ADDED: "No Details Added Yet",
    CREATE_PURCHASE_ORDER: "Create Purchase Order",
    PURCHASE_ORDER: "Purchase Order",
    SUPPLIER_DETAILS: "Supplier Details",
    CONTACT: "CONTACT",
    NO_CONTACT_FOUND: "No Contact Found",
    FINISH: "FINISH",
    GSTIN: "GSTIN",
    LAST_MODIFIED_ON: "Last Modified on",
    LAST_MODIFIED_BY: "Last Modified By",
    ADDING_PRODUCTS: "Adding Products",
    SELECTED_PRODUCTS: "Selected Products",
    NO_PRODUCT_ADDED: "No Product Added Yet",
    INWARD_WAREHOUSE: "Inward Warehouse",
    WE_ARE_READY_TO_PROCESS_YOUR_ORDER: "We are ready to Process Order",
    WILL_BE_PROCESS_THE_ORDER_OF_AMOUNT:
      "will be process the order of amount   ",
    CREATE_INVOICE: "Create Invoice",
    EDIT_ORDER: "Edit Order",
    RECENT_PURCHASE: "Recent Purchase from ",
    EMPTY_LIST: "No purchase order found.",
    SELECT_MSG:
      "Please select any order for confirming the delivery or Edit the order",
    DELIVERED: "Delivered",
    ASSETS: "Assets",
    VIEW_INCOMING_ORDER: "View Incoming Order",
    EDIT_PURCHASE_ORDER: "Edit Purchase Order",
  },
  DISPATCH_ORDER_MSG: {
    DISPATCH_ORDER: "Dispatch Order",
    ORDER_DATE: "Order Date",
    PRIMARY: "Primary",
    HANDLER: "Handler",
    SUPPLIER: "Supplier",
    SHIPPING_ADDRESS: "Shipping Address",
    BILLING_ADDRESS: "Billing Address",
    ORDER_VALUE: "Order Value",
    MODE: "Mode",
    ORDER_ID: "Order Id",
    SEE_MORE: "See more",
    VEHICLES_NO: "Vehicles Number",
    GSTIN_UIN: "GSTIN/UIN",
    INCL_GST: "(Incl. GST)",
    DISPATCH_ORDER_DETAILS: "Dispatch Order Details",
    DOWLOAD_INVOICE: "DOWNLOAD INVOICE",
    MARK_ORDER_COMPLETE: "MARK ORDER COMPLETE",
    OFFICE: "Office",
  },
  BOOKS_OF_ACCOUNTS_TEXT: {
    BOOKS_OF_ACCOUNTS: "Books of Accounts",
    RECEIVABLES: "Receivables",
    ADVANCES: "Advances",
    PAYABLE: "Payable",
    SELECT_MSG: "Please select any account to make payment.",
    PAYMENTS: "Payments",
    EMPTY_MSG: "Currently no accounts available.",
  },
  IN_REVIEW_TEXTS: {
    CONSUMED_CREDITS: "Consumed Credits",
    PAST_OVERDUE: "Past Overdue",
    EXCESS_CREDITS_CONSUMED: "Excess Credits Consumed",
  },
  COMPLETED_ORDER: {
    APPLY_DATE_RANGE: "Apply Date Range",
  },
};
