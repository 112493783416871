import { lazy } from "react";
import Skeleton from "./Skeleton";

const Cardlist = lazy(() => import("./CardList"));
const CustomCarousel = lazy(() => import("./Carousel"));
const ContainerForMRPOff = lazy(() => import("./ContainerForMRP_off"));
const ContainerForPercentageOff = lazy(() =>
  import("./ContainerForPercentageOff")
);
const CustomTabs = lazy(() => import("./CustomTabs"));
const QuadTextComponent = lazy(() => import("./QuadTextComponent"));
const DualTextComponent = lazy(() => import("./DualTextComponent"));
const ImageSlider = lazy(() => import("./ImageSlider"));
const NavigationBar = lazy(() => import("./NavigationBar"));
const OutStandingBox = lazy(() => import("./OutStandingBox"));
const CustomButton = lazy(() => import("./CustomButton"));
const Dropdown = lazy(() => import("./Dropdown"));
const SwitchButton = lazy(() => import("./SwitchButton"));
const ServerPaginationDataGrid = lazy(() =>
  import("./ServerPaginationDataGrid")
);
const ClientPaginationDataGrid = lazy(() =>
  import("./ClientPaginationDataGrid")
);
const CustomTableCell = lazy(() => import("./CustomTableCell"));
const PopOverComponent = lazy(() => import("./PopOverComponent"));
const ArrorwPicker = lazy(() => import("./ArrorwPicker"));
const MultiIconButton = lazy(() => import("./MultiIconButton"));
const ProfileDetailHeader = lazy(() => import("./ProfileDetailHeader"));
const PasswordInput = lazy(() => import("./PasswordInput"));
const CustomCheckbox = lazy(() => import("./CustomCheckbox"));
const CustomInfiniteScrolling = lazy(() => import("./CustomInfiniteScrolling"));
const SearchBar = lazy(() => import("./SearchBar"));
const CustomModal = lazy(() => import("./CustomModal"));
const DualButton = lazy(() => import("./DualButton"));
const CustomTextField = lazy(() => import("./CustomTextField"));
const DualButtonComponent = lazy(() => import("./DualButtonComponent"));
const AddPaymentComponent = lazy(() => import("./AddPaymentComponent"));
const ProductInfo = lazy(() => import("./ProductInfo"));
const DatePickerField = lazy(() => import("./DatePickerField"));
const DefaultActionCard = lazy(() => import("./DefaultActionCard"));
const CardHeaderWithRoundCorner = lazy(() =>
  import("./CardHeaderWithRoundCorner")
);
const BackButtonComponent = lazy(() => import("./BackButtonComponent"));
const BackButtonHeader = lazy(() => import("./BackButtonHeader"));
const ActionMessageButton = lazy(() => import("./ActionMessageButton"));
const CustomRadioButton = lazy(() => import("./CustomRadioButton"));
const DevelopmentMessageSnackbar = lazy(() =>
  import("./DevelopmentMessageSnackbar")
);
const EmptyListComponent = lazy(() => import("./EmptyListComponent"));
const CustomSnackbar = lazy(() => import("./CustomSnackbar"));
const PhoneInput = lazy(() => import("./PhoneInput"));
const SideNavigationBar = lazy(() => import("./SideNavigationBar"));
const HeadingWithButton = lazy(() => import("./HeadingWithButton"));
const MultiTextfield = lazy(() => import("./MultiTextfield"));
const CustomBackdrop = lazy(() => import("./CustomBackdrop"));
const CountryStateDropdown = lazy(() => import("./CountryStateDropdown"));
const ServerPaginationDataGrid2 = lazy(() =>
  import("./ServerPaginationDataGrid_2")
);
const CustomBottomSheet = lazy(() => import("./CustomBottomSheet"));
const ClientAccountModal = lazy(() => import("./ClientAccountModal"));
const CustomAccordion = lazy(() => import("./CustomAccordion"));
const TextWithArrow = lazy(() => import("./TextWithArrow"));
const NetPriceSection = lazy(() => import("./NetPriceSection"));
const PriceQuantitySection = lazy(() => import("./PriceQuantitySection"));
const AddressModal = lazy(() => import("./AddressModal"));
const CompanyRatePriceBook = lazy(() => import("./CompanyRatePriceBook"));
const ProductDetailCard = lazy(() => import("./ProductDetailCard"));
const UpdateCompanyRate = lazy(() => import("./UpdateCompanyRate"));
const ShowExistingComopanyRate = lazy(() =>
  import("./ShowExistingCompanyRate")
);
const AddOrUpdateProductComponent = lazy(() => import("./AddOrUpdateProductComponent"))
const CommonTable = lazy(() => import("./CommonTable"))
const PriceQuantitySectionForSalesOrder = lazy(() => import("./PriceQuantitySectionForSalesOrder"))
const CustomToggleButtonGroup = lazy(() => import("./CustomToggleButtonGroup"))

const Components = {
  Cardlist,
  CustomCarousel,
  ContainerForMRPOff,
  ContainerForPercentageOff,
  CustomTabs,
  QuadTextComponent,
  DualTextComponent,
  ImageSlider,
  NavigationBar,
  OutStandingBox,
  Skeleton,
  CustomButton,
  Dropdown,
  SwitchButton,
  CustomTableCell,
  ServerPaginationDataGrid,
  ClientPaginationDataGrid,
  CustomTextField,
  PopOverComponent,
  ArrorwPicker,
  MultiIconButton,
  ProfileDetailHeader,
  PasswordInput,
  CustomCheckbox,
  CustomInfiniteScrolling,
  CustomModal,
  DualButton,
  DualButtonComponent,
  AddPaymentComponent,
  ProductInfo,
  DatePickerField,
  BackButtonComponent,
  DefaultActionCard,
  CardHeaderWithRoundCorner,
  BackButtonHeader,
  ActionMessageButton,
  DevelopmentMessageSnackbar,
  CustomRadioButton,
  EmptyListComponent,
  SearchBar,
  CustomSnackbar,
  PhoneInput,
  SideNavigationBar,
  MultiTextfield,
  HeadingWithButton,
  CustomBackdrop,
  CountryStateDropdown,
  ServerPaginationDataGrid2,
  CustomBottomSheet,
  ClientAccountModal,
  CustomAccordion,
  TextWithArrow,
  NetPriceSection,
  PriceQuantitySection,
  AddressModal,
  CompanyRatePriceBook,
  ProductDetailCard,
  UpdateCompanyRate,
  ShowExistingComopanyRate,
  AddOrUpdateProductComponent,
  CommonTable,
  PriceQuantitySectionForSalesOrder,
  CustomToggleButtonGroup
};

export default Components;
