import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import TextRow from "./TextRow";
const BoxedTextRows = ({ borderRadius }) => {
  const customTheme = useTheme();
  return (
    <>
      <Box
        sx={(theme) => ({
          padding: `${theme.dimensions.dimen24} ${theme.dimensions.dimen24} ${theme.dimensions.dimen16} ${theme.dimensions.dimen24}`,
          border: `1px solid ${theme.border.borderColour.borderE0E3E1}`,
          borderRadius: borderRadius,
        })}
      >
        <Box
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.border.borderColour.borderE0E3E1}`,
            padding: `${theme.dimensions.dimen0} ${theme.dimensions.dimen0} ${theme.dimensions.dimen16} ${theme.dimensions.dimen0}`,
          })}
        >
          {[...Array(2)].map((key, index) => {
            return (
              <Box key={index}>
                <TextRow
                  height={customTheme.dimensions.dimen25}
                />
              </Box>
            )
          })}
        </Box>
        <Box
          sx={(theme) => ({
            padding: `${theme.dimensions.dimen24} ${theme.dimensions.dimen0} ${theme.dimensions.dimen16} ${theme.dimensions.dimen0}`,
          })}
        >
          {[...Array(2)].map((key, index) => {
            return (
              <Box key={index}>
                <TextRow
                  height={customTheme.dimensions.dimen25}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  );
};

export default BoxedTextRows;
