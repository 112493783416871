export const customTypography = {
  displayLarge: {
    fontSize: "57px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "64px",
    letterSpacing: "-0.25px",
  },
  displayLargeBold: {
    fontSize: "57px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "64px",
    letterSpacing: "-0.25px",
  },
  displayMedium: {
    fontSize: "45px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "52px",
  },
  displaySmall: {
    fontSize: "36px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "44px",
  },
  semiBold: {
    fontSize: "28px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "40px",
  },
  semiBoldMedium: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "40px",
  },
  semiBoldProminent: {
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },

  headlineMedium: {
    fontSize: "28px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "36px",
  },
  headlineBold: {
    fontSize: "18px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "24px",
  },
  headlineSmall: {
    fontSize: "24px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "32px",
  },
  titleLarge: {
    fontSize: "22px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "28px",
  },
  titleMedium: {
    fontSize: "16px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  titleBold: {
    fontSize: "22px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "28px",
  },

  titleSmall: {
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  lableLarge: {
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  lableLargeProminent: {
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  lableMedium: {
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  lableMediumProminent: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  lableSmall: {
    fontSize: "11px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  bodyLarge: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  bodyMedium: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  bodySmall: {
    fontSize: "12px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "16px",
  },
};
