import React, { lazy } from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

const FmcgTitle = lazy(() => import("./FmcgTitle"));
const Search = lazy(() => import("./Search"));
const HeaderIcons = lazy(() => import("./HeaderIcons"));

const HeaderComponent = ({ result }) => {
  const customTheme = useTheme();

  console.log("result header", result);

  return (
    <Grid
      container
      top={customTheme.dimensions.dimen0}
      sx={(theme) => ({
        paddingBottom: theme.dimensions.dimen12,
        paddingTop: theme.dimensions.dimen12,
        paddingLeft: theme.dimensions.percent.dimen6,
        paddingRight: theme.dimensions.percent.dimen6,
        // Here, we removed the position sticky property of Navigation Bar. Because it was causing the problem.The problem was suspense was working but header section was overlapping linear progress bar, That's was linear progress bar was not appearing on the screen.Now, it is working fine.
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Grid
        item
        md={customTheme.size.breakpoints.gridColumn3}
        sx={(theme) => ({
          textAlign: theme.alignment.alignItems.left,
          paddingLeft: theme.dimensions.percent.dimen2,
          paddingRight: theme.dimensions.percent.dimen2,
        })}
      >
        <FmcgTitle />
      </Grid>

      <Grid
        item
        md={customTheme.size.breakpoints.gridColumn4}
        xs={customTheme.size.breakpoints.gridColumn4}
        sx={(theme) => ({
          textAlign: theme.alignment.alignItems.center,
          m: theme.dimensions.dimenAuto,
          borderRadius: theme.dimensions.dimen10,
          justifyContent: theme.alignment.justifyContent.center,
          padding: `${theme.dimensions.dimen4} ${theme.dimensions.dimen16}  ${theme.dimensions.dimen4}  ${theme.dimensions.dimen0}`,
        })}
      >
        <Search accountInfo={result} />
      </Grid>

      <Grid
        item
        md={customTheme.size.breakpoints.gridColumn3}
        sx={(theme) => ({
          textAlign: theme.alignment.alignItems.end,
          paddingLeft: theme.dimensions.percent.dimen2,
          paddingRight: theme.dimensions.percent.dimen2,
        })}
      >
        <HeaderIcons accountInfo={result} />
      </Grid>
    </Grid>
  );
};
export default HeaderComponent;
