export const paletteLight = {
  primary: {
    main: "#006B5E",
    dark: "#00201B",
    light: "#76F8E2",
  },
  secondary: {
    main: "#295EA7",
    light: "#A9C7FF",
  },
  text: {
    primary: "#00201B",
    light: "#136E00",
    dark: "#0C5300",
  },
  warning: {
    main: "#F1C100",
  },
  success: {
    main: "#106000",
  },

  background: {
    default: "#f5f5f5",
    main: "#F7FAF8",
    dark: "#F0F3FF",
    paper: "#ffffff",
    blur: "#00000060",
    cards: "#F3FFFB",
    blocked: "#E6E9E7",
    clicked: "#F7FAF8", //orders: "#F7FAF8" SHivansh
    light: "#F9F9FF",
  },
  iconColor: {
    black: "#000000",
    arrowIcon: "#3DA526",
    background: "#F2F4F2",
    hover: "#E6E9E7",
    primary: "#1C1B1F",
    inActive: "#69778A",
  },
  error: {
    secondary: "#FF5449",
    main: "#BA1A1A",
    light: "#FFF0EE",
    dark: "#FFB4AB",
    primary: "#FFF8F1",
  },

  greyText: {
    primary: "#5C5F5E",
    main: "#8E9190",
    light: "#747876",
    dark: "#3F4946",
    background: "#A9ACAA",
    cards: "#F7F7F7",
  },
  yellowText: {
    primary: "#D0A600",
  },
  accountColor: "#55DBC6",

  deleteText: "#FF897D",
};
