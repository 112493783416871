import { useTheme } from "@emotion/react"
import { Alert, Skeleton, Stack } from "@mui/material"
import { constantData } from "../../Assets/constants";

const ListSkeleton = ({ spacing, rows, columns, width, border, borderColor }) => {
    const customTheme = useTheme();

    const checkProp = () => {
        return typeof rows === 'number' && typeof columns === 'number'
    }
    return (
        <>
            {checkProp() ?
                [...Array(rows)].map((item, key) => {
                    return (
                        <Stack
                            key={key}
                            direction={customTheme.alignment.direction.row}
                            padding={customTheme.dimensions.dimen10}
                            justifyContent={customTheme.alignment.justifyContent.spaceBetween}
                            borderBottom={`${customTheme.dimensions.dimen1} solid ${customTheme.border.borderColour.borderE0E3E1}`}
                            borderRight={`${customTheme.dimensions.dimen1} solid ${customTheme.border.borderColour.borderE0E3E1}`}
                            borderLeft={`${customTheme.dimensions.dimen1} solid ${customTheme.border.borderColour.borderE0E3E1}`}
                        >
                            {[...Array(columns)].map((item, key) => {
                                return (
                                    <Skeleton
                                        key={key}
                                        variant={customTheme.skeleton.variant.variantText}
                                        height={customTheme.dimensions.dimen30}
                                        width={width}
                                    />)
                            })}
                        </Stack >
                    )
                }) : (
                    <Alert severity={constantData.SEVERITY.ERROR}>
                        {constantData.REQUIREMENT_ALERT_MESSAGE}
                    </Alert>
                )}
        </>
    )
}
export default ListSkeleton;