import { useMutation } from "@apollo/client";
import { UPDATE_TOKEN } from "../GraphQLModules/Login/mutation";
import HeaderComponent from "../Header";
import RouteProvider from "../Routing";
import { useEffect, useState } from "react";
import { constantData } from "../../../Assets/constants";
import { Stack, Typography } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useTheme } from "@emotion/react";

const UpdateToken = ({ result }) => {
    const [updateToken] = useMutation(UPDATE_TOKEN, {
    onError: (error) => {
      console.log("error", error.message);
    },
  });
  const [authorizedUser, setAuthorizedUser] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const customTheme = useTheme();

  useEffect(() => {
    // Function to handle network status changes
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Add event listeners
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  useEffect(() => {
    if (result && result.accessToken) {
      updateToken({
        variables: {
          skip: true,
        },
      });
      setAuthorizedUser(true);

      let existingData = localStorage.getItem("fmcg_info");

      existingData = existingData ? JSON.parse(existingData) : {};
  
      let currentUser = existingData[result.account.localAccountId]
      // Update the existing data with azure_id
      if(!currentUser){
        existingData[result.account.localAccountId] = {}
       }
       existingData["active"] = result.account.localAccountId

      // Save the updated object back to localStorage
      localStorage.setItem("fmcg_info", JSON.stringify(existingData));

    }
  }, [result, updateToken]);

  console.log("result", result && result);

  return (
    <>
      {authorizedUser && (
        <>
          {isOnline ? (
            <>
              <HeaderComponent result={result && result.account} />
              <RouteProvider />
            </>
          ) : (
            <Stack
              spacing={2}
              sx={(theme) => ({
                marginTop: theme.dimensions.percent.dimen10,
                padding: theme.dimensions.dimen25,
                alignItems: theme.alignment.alignItems.center,
              })}
            >
              <WifiOffIcon
                sx={{ fontSize: customTheme.size.fontSize.fontSize60 }}
              />
              <Typography variant={constantData.TYPOGRAPHY_VARIANT.SEMI_BOLD}>
                Oops! Something went wrong.
              </Typography>
              <Stack
                sx={(theme) => ({
                  margin: theme.dimensions.dimen25,
                  alignItems: theme.alignment.alignItems.center,
                })}
              >
                <Typography
                  variant={constantData.TYPOGRAPHY_VARIANT.BODY_MEDIUM}
                >
                  It seems you're offline.
                </Typography>
                <Typography
                  variant={constantData.TYPOGRAPHY_VARIANT.BODY_MEDIUM}
                >
                  Please check your network connection.
                </Typography>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
export default UpdateToken;
