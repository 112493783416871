export const size = {

  breakpoints: {
    gridColumn1: 1,
    gridColumn2: 2,
    gridColumn3: 3,
    gridColumn4: 4,
    gridColumn5: 5,
    gridColumn6: 6,
    gridColumn7: 7,
    gridColumn8: 8,
    gridColumn9: 9,
    gridColumn10: 10,
    gridColumn11: 11,
    gridColumn12: 12,
  },
  fontWeight: {
    font600: "600",
    font500: "500",
    font400: "400",
  },

  fontSize: {
    fontSize14: "14px",
    fontSize16: "16px",
    fontSize18: "18px",
    fontSize20: "20px",
    fontSize22: "22px",
    fontSize24: "24px",
    fontSize60: "60px"
  },

  elevation: {
    elevation0: 0,
    elevation2: 2,
    elevation3: 3,
  },
  textTransform: {
    textTransformNone: "none",
  },
  zIndex: {
    zIndex1: 1,
    zIndex101: 101,
  },
  iconSize: {
    small: "small",
  }
};
