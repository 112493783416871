import { useTheme } from "@emotion/react";
import React from "react";
import { Skeleton } from "@mui/material";

const CarouselSkeleton = () => {
  const customTheme = useTheme();
  return (
    <>
      <Skeleton
        variant={customTheme.skeleton.variant.variantRounded}
        height={customTheme.dimensions.dimen245}
      />
    </>
  );
};

export default CarouselSkeleton;
