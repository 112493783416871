import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { palette } from "./palette";
import { customTypography } from "./typography";
import { border } from "./borders";
import { alignment } from "./alignment";
import { size } from "./size";
import { display } from "./display";
import { skeleton } from "./skeleton";
import { dimensions } from "./dimension";
import { components } from "./components";

export const ThemeCustomization = ({ children, themeMode }) => {
  const getTheme = (mode) => ({
    palette: {
      mode,
      ...(mode === "light" ? palette.paletteLight : palette.paletteDark),
    },

    typography: customTypography,
    border: border,
    dimensions: dimensions,
    alignment: alignment,
    size: size,
    display: display,
    skeleton: skeleton,
    components: components,
  });

  const customTheme = createTheme(getTheme(themeMode));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
