export const components = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
      sx: {
        boxShadow: 0,
        border: "1px solid #E0E3E1",
        borderRadius: "8px",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
  MuiCard: {
    defaultProps: {
      sx: {
        boxShadow: 0,
        border: "1px solid #E0E3E1",
        borderRadius: "8px",
        "&:hover": {
          // Target hover state
          // backgroundColor: "green", // Reset to default
          // color: "transparent",
        },
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      sx: {
        color: "#006B5E",
        fontWeight: 500,
        fontSize: "14px",
      },
    },
  },

    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          "&:hover": {
            cursor: "default",
          },
        },
      },
    },
};
