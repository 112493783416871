import React from "react";
import {
  Alert,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import { constantData } from "../../Assets/constants";

const TableSkeleton = ({ rowNum, cellNum, columns }) => {
  const customTheme = useTheme();

  const checkProp = () => {
    return (
      rowNum !== undefined &&
      rowNum !== null &&
      typeof rowNum === "number" &&
      rowNum >= 1 &&
      cellNum !== undefined &&
      cellNum !== null &&
      typeof cellNum === "number" &&
      cellNum >= 1
      // Array.isArray(columns) &&
      // columns.length > 0
    );
  };

  return (
    <>
      {checkProp() ? (
        <TableContainer
          sx={(theme) => ({
            borderRadius: `${theme.dimensions.dimen8}
                       ${theme.dimensions.dimen8}
                       ${theme.dimensions.dimen0} 
                       ${theme.dimensions.dimen0}`,

            border: theme.border.borderWidth,
            borderColor: theme.border.borderColour.borderE0E3E1,
          })}
        >
          <Table>
            <TableHead>
              <TableRow sx={{height: columns.height}}>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    sx={(theme) => ({
                      color: theme.palette.greyText.main,
                      fontWeight: theme.size.fontWeight.font400,
                      fontSize: theme.size.fontSize.fontSize14,
                    })}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(rowNum)].map((_, index) => (
                <TableRow key={index}>
                  {[...Array(cellNum)].map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant={customTheme.skeleton.variant.variantText}
                        height={customTheme.dimensions.dimen30}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity={constantData.SEVERITY.ERROR}>
          {constantData.REQUIREMENT_ALERT_MESSAGE}
        </Alert>
      )}
    </>
  );
};

TableSkeleton.propType = {
  column: PropTypes.array.isRequired,
  rowNum: PropTypes.number.isRequired,
  cellNum: PropTypes.number.isRequired,
};
export default TableSkeleton;
