import { useTheme } from "@emotion/react";
import { Button, Stack, Typography } from "@mui/material";
import { constantData } from "../../../Assets/constants";

const PrimaryPages = () => {
  const customTheme = useTheme();

  const handleLoginClick = () => {
    window.location.host = `localhost:3000/`;
  };

  return (
    <Stack
      height={customTheme.dimensions.percent.dimen100}
      justifyContent={customTheme.alignment.justifyContent.center}
      alignItems={customTheme.alignment.justifyContent.center}
    >
      <Typography variant={constantData.TYPOGRAPHY_VARIANT.TITLE_LARGE}>
        Welcome to the CRM Project
      </Typography>
      <Button onClick={handleLoginClick}>Login</Button>
    </Stack>
  );
};
export default PrimaryPages;
