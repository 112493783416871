import PrimaryRouteProvider from "../SubDomains/Primary/Routing";
import User from "../SubDomains/User";

const subDomainConfig = [
  {
    subDomain: "user",
    component: <PrimaryRouteProvider />,
  },
  {
    subDomain: "www",
    component: <User />,
  },
];

export default subDomainConfig;
