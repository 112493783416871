import CardSkeleton from "./CardSkeleton";
import CarouselSkeleton from "./CarouselSkeleton";
import TextRow from "./TextRow";
import TableSkeleton from "./TableSkeleton";
import BoxedTextRows from "./BoxedTextRows";
import ListSkeleton from "./ListSkeleton";
import ProfileHeaderSkeleton from "./ProfileHeaderSkeleton";
import CategorySkeleton from "./CategorySkeleton";
import PriceBookSkeleton from "./PriceBookSkeleton";

const Skeleton = {
  CarouselSkeleton,
  CardSkeleton,
  TextRow,
  TableSkeleton,
  BoxedTextRows,
  ListSkeleton,
  ProfileHeaderSkeleton,
  CategorySkeleton,
  PriceBookSkeleton
};

export default Skeleton;
