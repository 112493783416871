import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { b2cPolicies, protectedResources } from "../config/authConfig";
import { EventType, InteractionType } from "@azure/msal-browser";
import { ApolloProvider } from "@apollo/client";
import { clientSetup } from "../../../GraphQL/setup";
import UpdateToken from "./UpdateToken";
import { useEffect, useRef } from "react";

function compareIssuingPolicy(idTokenClaims, policyToCompare) {
  let tfpMatches =
    idTokenClaims.hasOwnProperty("tfp") &&
    idTokenClaims["tfp"].toLowerCase() === policyToCompare.toLowerCase();
  let acrMatches =
    idTokenClaims.hasOwnProperty("acr") &&
    idTokenClaims["acr"].toLowerCase() === policyToCompare.toLowerCase();
  return tfpMatches || acrMatches;
}

const Login = () => {
  const firstRender = useRef(true);
  const { instance } = useMsal();
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      scopes: protectedResources.apiTodoList.scopes.write,
      account: instance.getActiveAccount(),
      redirectUri: window.location.origin,

    }
  );

  console.log("instance", result && result.accessToken);
  console.log("Error", msalError);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [
              ...protectedResources.apiTodoList.scopes.read,
              ...protectedResources.apiTodoList.scopes.write,
            ],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);


  if (firstRender.current) {
    const initializeMsal = async () => {
      await instance.initialize();
    };
    initializeMsal();
    firstRender.current = false;
  }

  return (
    <AuthenticatedTemplate>
      <ApolloProvider
        client={clientSetup(
          result && result.accessToken,
          // "eyJhI1NiIsImtbGciOiJSUzpZCI6IZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJlMmQ4OThiYS1jMTQ1LTQ5ODUtYjVhNC1iZDc3ZmY0MWU3NmEiLCJpc3MiOiJodHRwczovL2F0aGF5bnN5c29yZy5iMmNsb2dpbi5jb20vZjkwMDU4ZTItNjliZC00OWJmLWI4ZGItYmQ2ZGIxN2Q1MDRhL3YyLjAvIiwiZXhwIjoxNzIxNDM0MTQyLCJuYmYiOjE3MjEzNzQxNDIsIm9pZCI6IjI1MjlmNzU5LWY1ZWEtNGYyMy04ODI4LTk1NDEyMmYxZDk4ZCIsInN1YiI6IjI1MjlmNzU5LWY1ZWEtNGYyMy04ODI4LTk1NDEyMmYxZDk4ZCIsIm5hbWUiOiJTaGl2YW5zaCBUaGFrdXIiLCJlbWFpbHMiOlsidGhha3Vyc2hpdmFuc2g3NUBnbWFpbC5jb20iXSwidGZwIjoiQjJDXzFfY3JtLXNpZ24taW4iLCJub25jZSI6IjAxOTBjOWUzLThmZDAtN2E5Mi05MmI3LWZjOTY4OTczZmY0MiIsInNjcCI6IlRvRG9MaXN0LlJlYWRXcml0ZSIsImF6cCI6ImYwODM5YzRhLWEwZjYtNDk0OS04NGE2LWRmMmZjZTM4Yjg1YSIsInZlciI6IjEuMCIsImlhdCI6MTcyMTM3NDE0Mn0.msAzPl1Wx_tjef-TfBVjkjzGB-QDhB87KSkp2jUfIKBiOjdaqpHcdWBRHp-6Q7QlCsXXfYy7xRVk_KWX5RSfNl_gHZ0PRlH7Soc2tMf_Cr04KfnhgkCSLeNe7j2MwFFszCxak4y4QKsAOi_xklXj1md_NepmOFE65wp1Xfi0chp4-k-6plRBPfrqRc2nGsrH5NRNmcjIZdHVftfpFKjLNAg32nw9B90TALuWgkXuZulCgXJtVt3e-YSGwPRftrGYFOWfCBxm2U4yP9oQtKoRPrgg7M1IGI4bi6CPybPdM41wgxVCobXdaIkF2xxe9zo6e7fUKIJNgxaAfFfkdf8d3g",
          instance
        )}
      >
        <UpdateToken result={result} />
      </ApolloProvider>
    </AuthenticatedTemplate>
  );
};
export default Login;
