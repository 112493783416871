import { useTheme } from "@emotion/react";
import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import TextRow from "./TextRow";

const ProfileHeaderSkeleton = () => {
    const customTheme = useTheme();

    return (
        <Stack
            direction={customTheme.alignment.direction.row}
            spacing={customTheme.dimensions.dimen5}
            alignItems={customTheme.alignment.alignItems.center}
            width={customTheme.dimensions.percent.dimen100}
        >
            <Stack width={customTheme.dimensions.percent.dimen8}>
                <Skeleton
                    variant={customTheme.skeleton.variant.variantCircular}
                    height={customTheme.dimensions.dimen56}
                />
            </Stack>

            <TextRow
                width={customTheme.dimensions.percent.dimen25}
                height={customTheme.dimensions.dimen20}
                rows={2}
            />
        </Stack>
    )
}
export default ProfileHeaderSkeleton;