import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  fetch: async (uri, options) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000);
    const response = await fetch(uri, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(timeout);

    return response;
  },
});

export const clientSetup = (item, msalInstance) => {
  return new ApolloClient({
    link: from([
      setContext(({ headers, query }) => {
        return {
          headers: {
            ...headers,
            authorization: item ? `Bearer ${item}` : "",
            skip:
              query.definitions[0].name.value === "UpdateToken" ? true : false,
            ...((query.definitions[0].name.value === "searchproducts" ||
              query.definitions[0].name.value === "SortProducts" ||
              query.definitions[0].name.value === "ProductFilters" ||
              // query.definitions[0].name.value ===
              //   "SearchProductsWithPriceBook" ||
              query.definitions[0].name.value === "ProductInfo" ||
              query.definitions[0].name.value === "AllProductsInfo") && {
              serverswitch: true,
            }),
          },
        };
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            if (err.extensions.code === "Unauthorized") {
              msalInstance.logoutRedirect();
              return;
            }
            window.dispatchEvent(
              new CustomEvent("apollo-error", {
                detail: err.message,
              })
            );
          }
          return;
        }
        if (networkError) {
          if (networkError.message === "signal is aborted without reason") {
            window.dispatchEvent(
              new CustomEvent("apollo-error", {
                detail:
                  "We are unable to connect server. Please try again later",
              })
            );
            return;
          }
          window.dispatchEvent(
            new CustomEvent("apollo-error", { detail: networkError.message })
          );
        }
      }),
      httpLink,
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
       fetchPolicy: "network-only", // Set the default fetch policy
      //  fetchPolicy: "cache-and-network"
      },
    },
  });
};

const setup = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  cache: new InMemoryCache(),
});

export default setup;
