export const paletteDark = {
  primary: {
    main: "#57DBC4",
    light: "#6380E4",
    dark: "#2A439B",
  },
  secondary: {
    main: "#3c61de",
    light: "#6380E4",
    dark: "#2A439B",
  },
  text: {
    primary: "#C4C7C5"  
  },

  background: {
    default: "#101413",
    paper: "#191C1B",
    blocked: "#272B2A"
  },

  error: {
    main:"#FFB4AB",
    light:"#FFF0EE",
    dark: "BA1A1A",
  },

  greyText: {
    main: "#8E9190",
    light: "#747876",
    dark: "#BEC9C5",
  },
  iconColor: "#C4C7C5",
  accountColor: "#005047",

};
