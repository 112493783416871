export const dimensions = {
  dimenAuto: "auto",
  dimenInherit: "inherit",
  dimenZeroAuto: "0px auto",
  dimen0: "0px",
  dimen1: "1px",
  dimen2: "2px",
  dimen4: "4px",
  dimen5: "5px",
  dimen6: "6px",
  dimen7: "7px",
  dimen8: "8px",
  dimen10: "10px",
  dimen11: "11px",
  dimen12: "12px",
  dimen14: "14px",
  dimen15: "15px",
  dimen16: "16px",
  dimen18: "18px",
  dimen20: "20px",
  dimen22: "22px",
  dimen24: "24px",
  dimen25: "25px",
  dimen28: "28px",
  dimen30: "30px",
  dimen32: "32px",
  dimen36: "36px",
  dimen40: "40px",
  dimen50: "50px",
  dimen56: "56px",
  dimen60: "60px",
  dimen64: "64px",
  dimen72: "72px",
  dimen80: "80px",
  dimen90: "90px",
  dimen95: "95px",
  dimen100: "100px",
  dimen108: "108px",
  dimen120: "120px",
  dimen125: "125px",
  dimen312: "312px",
  dimen500: "500px",
  dimenDecimal10: 10.8,
  dimen200: "200px",
  dimen220: "220px",
  dimen245: "245px",
  dimen320: "320px",
  dimen350: "350px",
  dimen440: "440px",
  dimen453: "453px",
  dimen50vh: "50vh",
  dimen60vh: "60vh",
  dimen76vh: "76vh",
  dimen100vh: "100vh",
  dimen100vw: "100vw",

  minus: {
    dimen10: "-10px",
    dimen80: "-80px",
    dimen129: "-129px",
    dimen2: "-2px",
    dimen4: "-4px",
    dimen50: "-50%",
  },
  percent: {
    dimen1: "1%",
    dimen2: "2%",
    dimen5: "5%",
    dimen6: "6%",
    dimen8: "8%",
    dimen10: "10%",
    dimen12: "12%",
    dimen15: "15%",
    dimen20: "20%",
    dimen25: "25%",
    dimen30: "30%",
    dimen50: "50%",
    dimen60: "60%",
    dimen70: "70%",
    dimen90: "90%",
    dimen100: "100%",
  },

  dimenWithOutPx: {
    dimen1: 1,
    dimen4: 4,
    dimen32: 32,
    dimen34: 34,
    dimen64: 64,
    dimen72: 72,
    dimen80: 80,
    dimen92: 92,
    dimen96: 96,
    dimen100: 100,
    dimen500: 500,
  },
};
