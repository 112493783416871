import { useTheme } from "@emotion/react";
import { Box, Skeleton, Stack } from "@mui/material";

const CardSkeleton = () => {
  const customTheme = useTheme();
  return (
    <>
      <Stack
        sx={(theme) => ({
          mt: theme.dimensions.dimen16,
          alignItems: theme.alignment.alignItems.center,
          mb: theme.dimensions.percent.dimen5,
        })}
      >
        <Skeleton
          variant={customTheme.skeleton.variant.variantCircular}
          sx={(theme) => ({
            height: theme.dimensions.dimen80,
            width: theme.dimensions.dimen80,
          })}
        />

        <Box
          sx={(theme) => ({
            mt: theme.dimensions.dimen16,
            p: theme.dimensions.dimen0,
            width: theme.dimensions.percent.dimen100,
          })}
        >
          <Skeleton variant={customTheme.skeleton.variant.variantText} />
          <Skeleton variant={customTheme.skeleton.variant.variantText} />
          <Skeleton variant={customTheme.skeleton.variant.variantText} />
        </Box>
      </Stack>
    </>
  );
};

export default CardSkeleton;
