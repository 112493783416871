import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";
import TextRow from "./TextRow";

const CategorySkeleton = () => {
  const customTheme = useTheme();
  return (
    <Stack direction={customTheme.alignment.direction.row} spacing={4}>
      {[...Array(5)].map((_, index) => (
        <TextRow
          height={customTheme.dimensions.dimen30}
          width={customTheme.dimensions.dimen80}
          key={index}
        />
      ))}
    </Stack>
  );
};
export default CategorySkeleton;
