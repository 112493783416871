import { useTheme } from "@emotion/react";
import { Skeleton, Alert, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { constantData } from "../../Assets/constants";

const TextRow = ({ height, width, marginRight, rows, direction, spacing }) => {
  const customTheme = useTheme();

  const checkProp = () => {
    return (
      height !== undefined && height !== null && typeof height === "string"
    );
  };

  return (
    <>
      {checkProp() ? (
        <Stack direction={direction} spacing={spacing} width={width}>
          {
            [...Array(rows)].map((item, key) => {
              return (
                <Skeleton
                  key={key}
                  variant={customTheme.skeleton.variant.variantText}
                  height={height}
                  sx={{
                    marginRight: marginRight
                  }}
                />
              )
            })
          }
        </Stack>

      ) : (
        <Alert severity={constantData.SEVERITY.ERROR}>
          {constantData.REQUIREMENT_ALERT_MESSAGE}
        </Alert>
      )}
    </>
  );
};

TextRow.propType = {
  height: PropTypes.string.isRequired,
};

export default TextRow;
