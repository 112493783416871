import { lazy } from "react";

const Dashboard = lazy(() => import("./Dashboard/index.js"));
const OrderProgression = lazy(() => import("./OrderProgression/index.js"));
const Team = lazy(() => import("./Team/index.js"));
const Page404 = lazy(() => import("../../Error/index.js"));
const MemberProfile = lazy(() => import("./MemberProfile/index.js"));
const ViewMemberDetail = lazy(() => import("./ViewMemberDetails.js/index.js"));
const Account = lazy(() => import("./Account/index.js"));
const OrderDetails = lazy(() => import("./OrderDetails/index.js"));
const Pipeline = lazy(() => import("./Pipeline/AddProduct/index.js"));
const Stock = lazy(() => import("./Stock/index.js"));
const ClientProfile = lazy(() => import("./ClientProfile/index.js"));
const ViewClientDetails = lazy(() => import("./ViewClientDetails/index.js"));
const Warehouse = lazy(() => import("./Warehouse/index.js"));
const WarehouseDetail = lazy(() => import("./WarehouseDetail/index.js"));
const ViewWarehouseDetails = lazy(() =>
  import("./ViewWarehouseDetails/index.js")
);
const ProductDetail = lazy(() => import("./ProductDetail"));
const AllProducts = lazy(() => import("./AllProducts"));
const Invoice = lazy(() => import("./Invoice"));
const AdjustOpeningStock = lazy(() => import("./AdjustOpeningStock"));
const ExecuteOrder = lazy(() => import("./ExecuteOrder"));
const PurchaseOrders = lazy(() => import("./PurchaseOrders"));
const DispatchOrder = lazy(() => import("./DispatchOrder"));
const ViewAllPurchaseOrder = lazy(() => import("./ViewAllPurchaseOrder"));
const AccountsBook = lazy(() => import("./AccountsBook"));
const CompletedOrders = lazy(() => import("./CompletedOrders"))

const Pages = {
  OrderProgression,
  Dashboard,
  Team,
  OrderDetails,
  Page404,
  MemberProfile,
  ViewMemberDetail,
  Account,
  Pipeline,
  Stock,
  ClientProfile,
  Warehouse,
  WarehouseDetail,
  ViewWarehouseDetails,
  ViewClientDetails,
  ProductDetail,
  AllProducts,
  Invoice,
  AdjustOpeningStock,
  ExecuteOrder,
  PurchaseOrders,
  DispatchOrder,
  ViewAllPurchaseOrder,
  AccountsBook,
  CompletedOrders
};

export default Pages;
