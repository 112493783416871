import React from "react";
import Error from "../../Error";
import PrimaryPages from "../Pages";

const PrimaryRouting = [
  {
    path: "/",
    element: <PrimaryPages />,
  },
  {
    path: "*",
    element: <Error navigateTo={"/"} />,
  },
];
export default PrimaryRouting;
