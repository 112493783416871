export const alignment = {
  justifyContent: {
    spaceBetween: "space-between",
    center: "center",
    start: "start",
    end: "end",
  },
  alignItems: {
    center: "center",
    right: "right",
    left: "left",
    flexEnd: "flex-end",
    flexStart: "flex-start",
    alignStretch: "stretch",
    end: "end",
    baseLine: "baseline",
  },
  align: {
    center: "center",
  },
  textAlign: {
    right: "right",
  },
  position: {
    relative: "relative",
    absolute: "absolute",
    fixed: "fixed",
    sticky: "sticky",
  },
  edge: {
    edgeStart: "start",
    edgeEnd: "end",
  },
  direction: {
    row: "row",
    column: "column",
    start: "start",
    end: "end",
  },
  orientation: {
    vertical: "vertical",
    horizontal: "horizontal",
  },
  anchorOrigin: {
    top: "top",
    bottom: "bottom",
    right: "right",
    center: "center",
  },
  overlap: {
    circular: "circular",
  },
};
