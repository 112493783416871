import React from "react";
import Pages from "../Pages";
import { ROUTES_PATH } from "../config/routesPath";

const Routing = [
  {
    path: "/team/memberProfile/:id",
    element: <Pages.MemberProfile />,
  },
  {
    path: "/team/view-member/:id",
    element: <Pages.ViewMemberDetail />,
  },
  {
    path: "/orders/in-review/detail/:id",
    element: <Pages.OrderDetails />,
  },
  {
    path: "/account/pipeline/:id",
    element: <Pages.Pipeline />,
  },
  {
    path: "/account/client-profile/:id",
    element: <Pages.ClientProfile />,
  },
  {
    path: "/account/view-client/:id",
    element: <Pages.ViewClientDetails />,
  },
  {
    path: "/dashboard/warehouse",
    element: <Pages.Warehouse />,
  },
  {
    path: "/warehouse/warehouse-detail/:id",
    element: <Pages.WarehouseDetail />,
  },
  {
    path: "/warehouse/view-warehouse-detail/:id",
    element: <Pages.ViewWarehouseDetails />,
  },
  {
    path: "/stock/product-detail/:id",
    element: <Pages.ProductDetail />,
  },
  {
    path: "/purchase-invoice",
    element: <Pages.Invoice />,
  },
  {
    path: "/adjust-opening",
    element: <Pages.AdjustOpeningStock />,
  },
  {
    path: "/orders/execute-order/:id",
    element: <Pages.ExecuteOrder />,
  },
  {
    path: "/orders/dispatch-order/:id",
    element: <Pages.DispatchOrder />,
  },
  {
    path: "/warehouse/purchase-orders",
    element: <Pages.PurchaseOrders />,
  },
  {
    path: "/warehouse/view-purchase-orders",
    element: <Pages.ViewAllPurchaseOrder />,
  },
  {
    path: "/accounts-book",
    element: <Pages.AccountsBook />,
  },
  {
    path: "/completed-orders",
    element: <Pages.CompletedOrders />,
  },
  {
    path: "*",
    element: <Pages.Page404 navigateTo={ROUTES_PATH.DASHBOARD} />,
  },
];
export default Routing;
