import Pages from "../Pages";
import Components from "../../../Components";
import { Navigate } from "react-router-dom";
import { ROUTES_PATH } from "../config/routesPath";

const Navigationbar = Components.NavigationBar;
const NestedRouting = {
  path: ROUTES_PATH.DEFAULTDASHBOARD,
  element: <Navigationbar />,

  children: [
    {
      path: ROUTES_PATH.DEFAULTDASHBOARD,
      element: <Navigate to={ROUTES_PATH.DASHBOARD} replace />,
    },
    {
      path: ROUTES_PATH.DASHBOARD,
      element: <Pages.Dashboard />,
    },
    {
      path: ROUTES_PATH.ORDERS,
      element: <Pages.OrderProgression />,
    },
    {
      path: ROUTES_PATH.TEAM,
      element: <Pages.Team />,
    },
    {
      path: ROUTES_PATH.ACCOUNTS,
      element: <Pages.Account />,
    },
    {
      path: ROUTES_PATH.STOCK,
      element: <Pages.Stock />,
    },
    {
      path: ROUTES_PATH.PRODUCTS,
      element: <Pages.AllProducts />,
    },
  ],
};
export default NestedRouting;
